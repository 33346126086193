//https://www.google.com/design/spec/components/cards.html#cards-content-blocks
// Card resting elevation: 2dp
.card {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: $border-radius-large;
  color: $black-color;
  background: $white-darker;
  width: 100%;

	.card-category:not([class*="text-"]) {
		color: $gray-darker;
	}
	.card-category{
		margin-top: 10px;

		.material-icons{
			position: relative;
			top: 8px;
			line-height: 0;
		  }
  }

  .form-check {
	  margin-top: 15px;
  }

	.card-title{
		margin-top: 0.625rem;

		&:last-child{
			margin-bottom: 0;
		}
	}

  // Cards have a default elevation of 2dp.
  @include box-shadow($bmd-shadow-2dp);
  @extend %std-font;


  &.no-shadow {
	.card-header-image,
	.card-header-image img {
	  box-shadow: none !important;
	  }
  }

  .card-body,
  .card-footer {
	padding: $padding-card-body-y $padding-card-body-x;
  }

  .card-body {
	  & + .card-footer{
		padding-top: 0rem;
		border: 0;
		border-radius: $border-radius-large;
	  }
  }

  .card-footer {
	display: flex;
	align-items: center;
	background-color: transparent;
	border: 0;

	.author,
	.stats {
		display: inline-flex;
	}

	.stats {
		color: $gray-color;

		.material-icons {
			position: relative;
			top: -10px;
			margin-right: 3px;
			margin-left: 3px;
			font-size: 18px;
		}
	}
  }

  &.bmd-card-raised {
	// Card raised elevation: 8dp
	@include box-shadow($bmd-shadow-8dp);
  }

  @include media-breakpoint-up(lg) {
	// On desktop, cards can have a resting elevation of 0dp and gain an elevation of 8dp on hover.
	&.bmd-card-flat {
	  box-shadow: none;
	}
  }

	.card-header {
	  border-bottom: none;
	  background: transparent;
		.title{
			color: $white-color;
		}

		&:not([class*="card-header-"]){
			// @include shadow-big();
		}

		.nav-tabs {
			padding: 0;
		}

		&.card-header-image {
			position: relative;
			padding: 0;
			z-index: 1;
			margin-left: 15px;
			margin-right: 15px;
			margin-top: -30px;
			border-radius: $border-radius-large;

			img {
				width: 100%;
				border-radius: $border-radius-large;
				pointer-events: none;
				@include shadow-big-image();
			}
			.card-title {
				position: absolute;
				bottom: 15px;
				left: 15px;
				color: $white-color;
				font-size: $font-size-h4;
				text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
			}

			.colored-shadow{
				transform: scale(0.94);
				top: 12px;
				filter: blur(12px);
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				z-index: -1;
				transition: opacity .45s;
				opacity: 0;
			}

			&.no-shadow{
				box-shadow: none;

				&.shadow-normal{
					@include shadow-big();
				}

				.colored-shadow{
					display: none !important;
				}
			}
		}

		&[color="blue"]{
			color: $white !important;
			background: linear-gradient(60deg, $blue-800, $blue-900);
		}
	}





	.card-header-primary .card-icon,
	.card-header-primary .card-text,
	.card-header-primary:not(.card-header-icon):not(.card-header-text),
	&.bg-primary,
	&.card-rotate.bg-primary .front,
	&.card-rotate.bg-primary .back{
	  background: linear-gradient(60deg, $purple-400, $purple-600);
	}
	.card-header-info .card-icon,
	.card-header-info .card-text,
	.card-header-info:not(.card-header-icon):not(.card-header-text),
	&.bg-info,
	&.card-rotate.bg-info .front,
	&.card-rotate.bg-info .back{
	  background: linear-gradient(60deg, $cyan-400, $cyan-600);
	}
	.card-header-success .card-icon,
	.card-header-success .card-text,
	.card-header-success:not(.card-header-icon):not(.card-header-text),
	&.bg-success,
	&.card-rotate.bg-success .front,
	&.card-rotate.bg-success .back{
	  background: linear-gradient(60deg, $green-400, $green-600);
	}
	.card-header-warning .card-icon,
	.card-header-warning .card-text,
	.card-header-warning:not(.card-header-icon):not(.card-header-text),
	&.bg-warning,
	&.card-rotate.bg-warning .front,
	&.card-rotate.bg-warning .back{
	  background: linear-gradient(60deg, $orange-400, $orange-600);
	}
	.card-header-danger .card-icon,
	.card-header-danger .card-text,
	.card-header-danger:not(.card-header-icon):not(.card-header-text),
	&.bg-danger,
	&.card-rotate.bg-danger .front,
	&.card-rotate.bg-danger .back{
	  background: linear-gradient(60deg, $red-400, $red-600);
	}

	.card-header-white .card-icon,
	.card-header-white .card-text,
	.card-header-white:not(.card-header-icon):not(.card-header-text),
	&.bg-white,
	&.card-rotate.bg-white .front,
	&.card-rotate.bg-white .back{
	  background: linear-gradient(60deg, $white, $white);
	}

	.card-header-rose .card-icon,
	.card-header-rose .card-text,
	.card-header-rose:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-rose .front,
	&.card-rotate.bg-rose .back{
	  background: linear-gradient(60deg, $pink-400, $pink-600);
	}
	
	
	.card-header-red .card-icon,
	.card-header-red .card-text,
	.card-header-red:not(.card-header-icon):not(.card-header-text),
	&.bg-black,
	&.card-rotate.bg-red .front,
	&.card-rotate.bg-red .back {
	  background: linear-gradient(60deg, black, red);
	}
	
	.card-header-red-1 .card-icon,
	.card-header-red-1 .card-text,
	.card-header-red-1:not(.card-header-icon):not(.card-header-text),
	&.bg-black,
	&.card-rotate.bg-red-1 .front,
	&.card-rotate.bg-red-1 .back {
	  background: linear-gradient(60deg, red, #b30000);
	}
	
	.card-header-red-2 .card-icon,
	.card-header-red-2 .card-text,
	.card-header-red-2:not(.card-header-icon):not(.card-header-text),
	&.bg-black,
	&.card-rotate.bg-red-2 .front,
	&.card-rotate.bg-red-2 .back {
	  background: linear-gradient(60deg, #b30000, #800000);
	}
	
	.card-header-red-3 .card-icon,
	.card-header-red-3 .card-text,
	.card-header-red-3:not(.card-header-icon):not(.card-header-text),
	&.bg-black,
	&.card-rotate.bg-red-3 .front,
	&.card-rotate.bg-red-3 .back {
	  background: linear-gradient(60deg, #800000, #4d0000);
	}
	
	.card-header-red-4 .card-icon,
	.card-header-red-4 .card-text,
	.card-header-red-4:not(.card-header-icon):not(.card-header-text),
	&.bg-black,
	&.card-rotate.bg-red-4 .front,
	&.card-rotate.bg-red-4 .back {
	  background: linear-gradient(60deg, #4d0000, black);
	}
	.card-header-red-5 .card-icon,
	.card-header-red-5 .card-text,
	.card-header-red-5:not(.card-header-icon):not(.card-header-text),
	&.bg-black,
	&.card-rotate.bg-red-5 .front,
	&.card-rotate.bg-red-5 .back {
		background: linear-gradient(60deg, black, red);
	}
	
	.card-header-red-6 .card-icon,
	.card-header-red-6 .card-text,
	.card-header-red-6:not(.card-header-icon):not(.card-header-text),
	&.bg-black,
	&.card-rotate.bg-red-6 .front,
	&.card-rotate.bg-red-6 .back {
		background: linear-gradient(60deg, black, red);
	}
	.customer-info-red {
	  background: linear-gradient(60deg, red, black) !important;
	}
	
	.customer-info-red .card-title {
	  font-weight: 900;
	  color: #fff !important;
	}
	
	.customer-info-red small {
	  color: #fff !important;
	}
	
	.selected-item-red {
	  background: linear-gradient(60deg, red, #800000) !important;
	}
	
	.border-red {
	  border-color: red !important;
	}
	
	
	.card-header-blue .card-icon,
	.card-header-blue .card-text,
	.card-header-blue:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-blue .front,
	&.card-rotate.bg-blue .back{
	  background: linear-gradient(60deg, $light-blue-900, $light-blue-700);
	}

	.card-header-blue-1 .card-icon,
	.card-header-blue-1 .card-text,
	.card-header-blue-1:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-blue-1 .front,
	&.card-rotate.bg-blue-1 .back{
	  background: linear-gradient(60deg, $light-blue-700, $light-blue-600);
	}

	.card-header-blue-2 .card-icon,
	.card-header-blue-2 .card-text,
	.card-header-blue-2:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-blue-2 .front,
	&.card-rotate.bg-blue-2 .back{
	  background: linear-gradient(60deg, $light-blue-700, $light-blue-300);
	}

	.card-header-blue-3 .card-icon,
	.card-header-blue-3 .card-text,
	.card-header-blue-3:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-blue-3 .front,
	&.card-rotate.bg-blue-3 .back{
	  background: linear-gradient(60deg, $light-blue-700, $light-blue-200);
	}

	.card-header-blue-4 .card-icon,
	.card-header-blue-4 .card-text,
	.card-header-blue-4:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-blue-4 .front,
	&.card-rotate.bg-blue-4 .back{
	  background: linear-gradient(60deg, $light-blue-700, $light-blue-100);
	}

	.card-header-blue-5 .card-icon,
	.card-header-blue-5 .card-text,
	.card-header-blue-5:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-blue-5 .front,
	&.card-rotate.bg-blue-5 .back{
	  background: linear-gradient(60deg, $light-blue-900, $light-blue-700);
	}

	.card-header-blue-6 .card-icon,
	.card-header-blue-6 .card-text,
	.card-header-blue-6:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-blue-6 .front,
	&.card-rotate.bg-blue-6 .back{
	  background: linear-gradient(60deg, $light-blue-900, $light-blue-700);
	}

	.card-header-azure .card-icon,
	.card-header-azure .card-text,
	.card-header-azure:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-azure .front,
	&.card-rotate.bg-azure .back{
	  background: linear-gradient(60deg, $light-blue-600, $light-blue-600);
	}

	.customer-info-azure {
		background: linear-gradient(60deg,$light-blue-400, $light-blue-400) !important;
	}
	.customer-info-azure .card-title {
		font-weight: 900;
		color: #1f242b !important;
	}

	.customer-info-azure small {
		color: #1f242b !important;
	}
	.customer-info-blue {
		background: linear-gradient(60deg, $light-blue-400, $light-blue-400) !important;
	}

	.customer-info-blue small {
		color: #1f242b !important;
	}

	.customer-info-blue .card-title {
		font-weight: 900;
		color: #1f242b !important;
	}

	.customer-info-blue small {
		color: #1f242b !important;
	}

	.customer-info-green {
		background: linear-gradient(60deg, $green-400, $green-400) !important;
	}

	.customer-info-green small {
		color: #1f242b !important;
	}

	.customer-info-green .card-title {
		font-weight: 900;
		color: #1f242b !important;
	}

	.customer-info-purple {
		background: linear-gradient(60deg, $purple-400, $purple-400) !important;
		color: #fff !important;
	}

	.customer-info-purple .card-title {
		font-weight: 900;
		color: #fff !important;
	}
	.customer-info-purple small {
		color: #fff !important;
	}

	.selected-item-azure {
		background: linear-gradient(60deg,$light-blue-100, $light-blue-100) !important;
	}
	.selected-item-blue {
		background: linear-gradient(60deg, $light-blue-100, $light-blue-100) !important;
	}

	.selected-item-green {
		background: linear-gradient(60deg, $green-100, $green-100) !important;
	}

	.selected-item-purple {
		background: linear-gradient(60deg, $purple-100, $purple-100) !important;
	}


	.border-azure {
		border-color: $light-blue-200 !important;
	}

	.border-blue {
		border-color: $light-blue-200 !important;
	}

	.border-green {
		border-color: $green-200 !important;
	}

	.border-purple {
		border-color: $purple-200 !important;
	}

	.card-header-azure-1 .card-icon,
	.card-header-azure-1 .card-text,
	.card-header-azure-1:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-azure-1 .front,
	&.card-rotate.bg-azure-1 .back{
	  background: linear-gradient(60deg, $light-blue-700, $light-blue-600);
	}

	.card-header-azure-2 .card-icon,
	.card-header-azure-2 .card-text,
	.card-header-azure-2:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-azure-2 .front,
	&.card-rotate.bg-azure-2 .back{
	  background: linear-gradient(60deg, $light-blue-700, $light-blue-300);
	}

	.card-header-azure-3 .card-icon,
	.card-header-azure-3 .card-text,
	.card-header-azure-3:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-azure-3 .front,
	&.card-rotate.bg-azure-3 .back{
	  background: linear-gradient(60deg, $light-blue-700, $light-blue-200);
	}

	.card-header-azure-4 .card-icon,
	.card-header-azure-4 .card-text,
	.card-header-azure-4:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-azure-4 .front,
	&.card-rotate.bg-azure-4 .back{
	  background: linear-gradient(60deg, $light-blue-700, $light-blue-100);
	}

	.card-header-azure-5 .card-icon,
	.card-header-azure-5 .card-text,
	.card-header-azure-5:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-azure-5 .front,
	&.card-rotate.bg-azure-5 .back{
	  background: linear-gradient(60deg, $light-blue-900, $light-blue-700);
	}

	.card-header-azure-6 .card-icon,
	.card-header-azure-6 .card-text,
	.card-header-azure-6:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-azure-6 .front,
	&.card-rotate.bg-azure-6 .back{
	  background: linear-gradient(60deg, $light-blue-900, $light-blue-700);
	}

	.card-header-purple .card-icon,
	.card-header-purple .card-text,
	.card-header-purple:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-purple .front,
	&.card-rotate.bg-purple .back{
	  background: linear-gradient(60deg, $purple-900, $purple-700);
	}

	.card-header-purple-1 .card-icon,
	.card-header-purple-1 .card-text,
	.card-header-purple-1:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-purple-1 .front,
	&.card-rotate.bg-purple-1 .back{
	  background: linear-gradient(60deg, $purple-700, $purple-500);
	}

	.card-header-purple-2 .card-icon,
	.card-header-purple-2 .card-text,
	.card-header-purple-2:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-purple-2 .front,
	&.card-rotate.bg-purple-2 .back{
	  background: linear-gradient(60deg, $purple-700, $purple-300);
	}

	.card-header-purple-3 .card-icon,
	.card-header-purple-3 .card-text,
	.card-header-purple-3:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-purple-3 .front,
	&.card-rotate.bg-purple-3 .back{
	  background: linear-gradient(60deg, $purple-700, $purple-200);
	}

	.card-header-purple-4 .card-icon,
	.card-header-purple-4 .card-text,
	.card-header-purple-4:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-purple-4 .front,
	&.card-rotate.bg-purple-4 .back{
	  background: linear-gradient(60deg, $purple-700, $purple-100);
	}

	.card-header-purple-5 .card-icon,
	.card-header-purple-5 .card-text,
	.card-header-purple-5:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-purple-5 .front,
	&.card-rotate.bg-purple-5 .back{
	  background: linear-gradient(60deg, $purple-900, $purple-700);
	}

	.card-header-purple-6 .card-icon,
	.card-header-purple-6 .card-text,
	.card-header-purple-6:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-purple-6 .front,
	&.card-rotate.bg-purple-6 .back{
	  background: linear-gradient(60deg, $purple-900, $purple-500);
	}



	.card-header-green .card-icon,
	.card-header-green .card-text,
	.card-header-green:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-green .front,
	&.card-rotate.bg-green .back{
	  background: linear-gradient(60deg, $green-900, $green-700);
	}




	.card-header-green-1 .card-icon,
	.card-header-green-1 .card-text,
	.card-header-green-1:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-green-1 .front,
	&.card-rotate.bg-green-1 .back{
	  background: linear-gradient(60deg, $green-700, $green-600);
	}

	.card-header-green-2 .card-icon,
	.card-header-green-2 .card-text,
	.card-header-green-2:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-green-2 .front,
	&.card-rotate.bg-green-2 .back{
	  background: linear-gradient(60deg, $green-700, $green-300);
	}

	.card-header-green-3 .card-icon,
	.card-header-green-3 .card-text,
	.card-header-green-3:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-green-3 .front,
	&.card-rotate.bg-green-3 .back{
	  background: linear-gradient(60deg, $green-700, $green-200);
	}

	.card-header-green-4 .card-icon,
	.card-header-green-4 .card-text,
	.card-header-green-4:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-green-4 .front,
	&.card-rotate.bg-green-4 .back{
	  background: linear-gradient(60deg, $green-700, $green-100);
	}

	.card-header-green-5 .card-icon,
	.card-header-green-5 .card-text,
	.card-header-green-5:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-green-5 .front,
	&.card-rotate.bg-green-5 .back{
	  background: linear-gradient(60deg, $green-900, $green-700);
	}

	.card-header-green-6 .card-icon,
	.card-header-green-6 .card-text,
	.card-header-green-6:not(.card-header-icon):not(.card-header-text),
	&.bg-rose,
	&.card-rotate.bg-green-6 .front,
	&.card-rotate.bg-green-6 .back{
	  background: linear-gradient(60deg, $green-900, $green-700);
	}

	.card-header-primary .card-icon,
	.card-header-primary:not(.card-header-icon):not(.card-header-text),
	.card-header-primary .card-text{
		@include shadow-big-color($brand-primary);

		//@include shadow-8dp-color($brand-primary);
		//@include shadow-16dp-color($brand-primary);
	}
	.card-header-danger .card-icon,
	.card-header-danger:not(.card-header-icon):not(.card-header-text),
	.card-header-danger .card-text{
		@include shadow-big-color($brand-danger);
	}

	.card-header-rose .card-icon,
	.card-header-rose:not(.card-header-icon):not(.card-header-text),
	.card-header-rose .card-text{
		@include shadow-big-color($brand-rose);
	}

	.card-header-warning .card-icon,
	.card-header-warning:not(.card-header-icon):not(.card-header-text),
	.card-header-warning .card-text{
		@include shadow-big-color($brand-warning);
	}

	.card-header-info .card-icon,
	.card-header-info:not(.card-header-icon):not(.card-header-text),
	.card-header-info .card-text{
		@include shadow-big-color($brand-info);
	}

	.card-header-success .card-icon,
	.card-header-success:not(.card-header-icon):not(.card-header-text),
	.card-header-success .card-text{
		@include shadow-big-color($brand-success);
	}

	[class*="card-header-"],
	&[class*="bg-"]{
		color: $white-color;

		.card-title a,
		.card-title,
		.icon i{
			color: $white-color;
		}

		.icon i{
			border-color: rgba(255, 255, 255, 0.25);
		}
		.author a,
		.stats,
		.card-category,
		.card-description{
			color: $white-transparent;
		}

		.author a{
			&:hover,
			&:focus,
			&:active{
				color: $white-color;
			}
		}
	}

	.author{
		.avatar{
			width: 30px;
			height: 30px;
			overflow: hidden;
			border-radius: 50%;
			margin-right: 5px;
		}

		a{
			color: $black-color;
			text-decoration: none;

			.ripple-container{
				display: none;
			}
		}
	}

	.card-category-social{
		.fa{
			font-size: 24px;
			position: relative;
			margin-top: -4px;
			top: 2px;
			margin-right: 5px;
		}

		.material-icons{
			position: relative;
			top: 5px;
		}
	}

	&[class*="bg-"],
	&[class*="bg-"] .card-body{
		border-radius: $border-radius-large;

		h1,
		h2,
		h3{
		  small{
			  color: $white-transparent;
		  }
		}
	}

	.card-stats{
		background: transparent;
		display: flex;

		.author,
		.stats{
			display: inline-flex;
		}
	}
}

.card {
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.34);

  .table tr:first-child td{
	border-top: none;
  }

  .card-title{
	margin-top: 0;
	margin-bottom: 15px;
  }

  .card-body{
	padding: $padding-card-body-y 20px;
	position: relative;

  }

  .card-header {
	z-index: 3 !important;

	.card-title{
	  margin-bottom: 3px;
	}

	.card-category{
	  margin: 0;
	}

	&.card-header-text {
		display: inline-block;

		&:after {
			content: "";
			display: table;
		}
	}

	&.card-header-icon,
	&.card-header-text {
		i {
			width: 33px;
			height: 33px;
			text-align: center;
			line-height: 33px;
		}
		.card-title{
		  margin-top: 15px;
		  color: $black-color;
		}
		h4{
		  font-weight: 300;
		}
	}

	&.card-header-tabs {
		.nav-tabs {
			background: transparent;
			padding: 0;
		}
		.nav-tabs-title {
			float: left;
			padding: 10px 10px 10px 0;
			line-height: 24px;
		}
	}
  }

  &.card-plain {
	.card-header {
	  &.card-header-icon + .card-body .card-title,
	  &.card-header-icon + .card-body .card-category {
		  margin-top: -20px;
	  }
	}
  }

  .card-actions {
	  position: absolute;
	  z-index: 1;
	  top: -50px;
	  width: calc(100% - 30px);
	  left: 17px;
	  right: 17px;
	  text-align: center;

	  .card-header{
		padding: 0;
		min-height: 160px;
	  }

	  .btn {
		  padding-left: 12px;
		  padding-right: 12px;
	  }
	  .fix-broken-card {
		  position: absolute;
		  top: -65px;
	  }
  }

  &.card-chart {
	.card-footer i:nth-child(1n+2){
	  width: 18px;
	  text-align: center;
	}

	.card-category{
	  margin: 0;
	}
  }

  .card-body + .card-footer,
  .card-footer{
	padding: 0;
	padding-top: 10px;
	margin: 0 15px 10px;
	border-radius: 0;
	justify-content: space-between;
	align-items: center;

	h6 {
	  width: 100%;
	}

	.stats{
	  color: #999999;
	  font-size: 12px;
	  line-height: 22px;

	  .card-category{
		padding-top: 7px;
		padding-bottom: 7px;
		margin: 0;
	  }

	  .material-icons{
		position: relative;
		top: 4px;
		font-size: 16px;
	  }
	}
  }
  [class*="card-header-"] {
	  margin: 0px 15px 0;
	  padding: 0;

	  .card-title + .card-category{
		color: rgba(255, 255, 255, 0.62);
		a {
		  color: $white-color;
		}
	  }

	  &:not(.card-header-icon):not(.card-header-text):not(.card-header-image){
		border-radius: $border-radius-base;
		margin-top: -20px;
		padding: 15px;
	  }

	  .card-icon,
	  .card-text{
		border-radius: $border-radius-base;
		background-color: $gray-color;
		padding: 15px;
		margin-top: -20px;
		margin-right: 15px;
		float: left;
	  }

	  .card-text{
		float: none;
		display: inline-block;
		margin-right: 0;

		.card-title{
		  color: $white-color;
		  margin-top: 0;
		}
	  }

	  position: relative;

	  .ct-chart{
		.card-title{
			color: $white-color;
		}
		.card-category{
			margin-bottom: 0;
			color: rgba($white-color, .62);
		}

		.ct-label{
			color: rgba($white-color, .7);
		}
		.ct-grid{
			stroke: rgba(255, 255, 255, 0.2);
		}
		.ct-series-a .ct-point,
		.ct-series-a .ct-line,
		.ct-series-a .ct-bar,
		.ct-series-a .ct-slice-donut{
			stroke: rgba(255,255,255,.8);
		}
		.ct-series-a .ct-slice-pie,
		.ct-series-a .ct-area{
			fill: rgba(255,255,255,.4);
		}
		.ct-series-a .ct-bar{
		  stroke-width: 10px;
		}
		.ct-point{
		  stroke-width: 10px;
		  stroke-linecap: round;
		}
		.ct-line{
		  fill: none;
		  stroke-width: 4px;
		}
	  }
  }

  [data-header-animation="true"] {
	  @include transform-translate-y(0);
	  -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
	  -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
	  -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
	  -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
	  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  }

  &:hover {
	  [data-header-animation="true"]{
		  @include transform-translate-y(-50px);
	  }
  }

  .map {
	height: 280px;
	border-radius: $border-radius-large;
	margin-top: 15px;

	&.map-big{
	  height: 420px;
	}
  }

  .card-body.table-full-width{
	padding: 0;
  }

  .card-plain .card-header-icon {
	margin-right: 15px !important;
  }
}

.table-sales{
	margin-top: 40px;
}

.iframe-container {
	width: 100%;

	iframe {
		width: 100%;
		height: 500px;
		border: 0;
		@include shadow-big();
	}
}

.card-wizard {
  .nav.nav-pills {
	.nav-item {
	  margin: 0;

	  .nav-link {
		padding: 6px 15px !important;
	  }
	}
  }
  .nav-pills:not(.flex-column) .nav-item + .nav-item:not(:first-child) {
	margin-left: 0;
  }

  .nav-item .nav-link.active,
  .nav-item .nav-link:hover,
  .nav-item .nav-link:focus {
	background-color: inherit !important;
	box-shadow: none !important;
  }

  .input-group-text {
	padding: 6px 15px 0px !important;
  }
  .card-footer {
	border-top: none !important;
  }
}

.card-chart,
.card-product {
  .card-body + .card-footer {
	border-top: 1px solid #eee;
  }
}

.card-product{
  .price{
	color: inherit;
  }
}

.card-collapse {
  margin-bottom: 15px;

  .card .card-header a[aria-expanded="true"]{
	color: #e91e63;
  }
}
